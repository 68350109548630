<template>
  <div class="container">
    <p class="info" v-if="AnswerData && AnswerData.QuestionnaireId">编号: {{AnswerData.QuestionnaireId}}</p>
    <p class="info" v-if="AnswerData && AnswerData.RealName">姓名: {{AnswerData.RealName}}</p>
    <p class="info" v-if="AnswerData && AnswerData.HospitalTitle">医院: {{AnswerData.HospitalTitle}}</p>
    <p class="info" v-if="AnswerData && AnswerData.CreateTime">提交时间: {{AnswerData.CreateTime}}</p>
    <div class="block">
      <p class="title">缝针颜色</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleColor && AnswerData.NeedleColor.split('|').includes('黑色')}">黑色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleColor && AnswerData.NeedleColor.split('|').includes('银色')}">银色</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.NeedleColorOther">
        <span>其他：<span class="red">{{AnswerData.NeedleColorOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.NeedleColorOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝针长度(mm)</p>
      <span class="font" v-if="AnswerData && AnswerData.NeedleLength">{{AnswerData.NeedleLength}}</span>
      <!-- <input class="input" type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.NeedleLength" /> -->
    </div>

    <div class="block">
      <p class="title">缝针特征</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleFeature && AnswerData.NeedleFeature.split('|').includes('强度')}">强度</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleFeature && AnswerData.NeedleFeature.split('|').includes('硬度')}">硬度</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleFeature && AnswerData.NeedleFeature.split('|').includes('锋利度')}">锋利度</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.NeedleFeatureOther">
        <span>其他：<span class="red">{{AnswerData.NeedleFeatureOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.NeedleFeatureOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝针针形</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('圆针')}">圆针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('角针')}">角针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('圆体角针')}">圆体角针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('精细美容针')}">精细美容针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('钝针')}">钝针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('防刺伤针')}">防刺伤针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('铲针')}">铲针</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleShape && AnswerData.NeedleShape.split('|').includes('直针')}">直针</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.NeedleShapeOther">
        <span>其他：<span class="red">{{AnswerData.NeedleShapeOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.NeedleShapeOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝针弧度</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleCurvature && AnswerData.NeedleCurvature.split('|').includes('1/2C')}">1/2C</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleCurvature && AnswerData.NeedleCurvature.split('|').includes('3/8C')}">3/8C</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.NeedleCurvature && AnswerData.NeedleCurvature.split('|').includes('5/8C')}">5/8C</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.NeedleCurvatureOther">
        <span>其他：<span class="red">{{AnswerData.NeedleCurvatureOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.NeedleCurvatureOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝线线径（粗细规格）</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('5')}">5</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('4')}">4</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('3')}">3</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('2')}">2</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('1')}">1</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('0')}">0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('2-0')}">2-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('3-0')}">3-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('4-0')}">4-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('5-0')}">5-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('6-0')}">6-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('7-0')}">7-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('8-0')}">8-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('9-0')}">9-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('10-0')}">10-0</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureDiameter && AnswerData.SutureDiameter.split('|').includes('11-0')}">11-0</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.SutureDiameterOther">
        <span>其他：<span class="red">{{AnswerData.SutureDiameterOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.SutureDiameterOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝线颜色</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('黑色')}">黑色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('白色')}">白色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('紫色')}">紫色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('蓝色')}">蓝色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('绿色')}">绿色</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureColor && AnswerData.SutureColor.split('|').includes('未染色')}">未染色</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.SutureColorOther">
        <span>其他：<span class="red">{{AnswerData.SutureColorOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.SutureColorOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">缝线长度(cm)</p>
      <span class="font" v-if="AnswerData && AnswerData.SutureLength">{{AnswerData.SutureLength}}</span>
      <!-- <input class="input" type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.SutureLength" /> -->
    </div>

    <div class="block">
      <p class="title">缝线功能</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureFunction && AnswerData.SutureFunction.split('|').includes('止血')}">止血</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureFunction && AnswerData.SutureFunction.split('|').includes('抗菌')}">抗菌</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.SutureFunction && AnswerData.SutureFunction.split('|').includes('防渗漏')}">防渗漏</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.SutureFunctionOther">
        <span>其他：<span class="red">{{AnswerData.SutureFunctionOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.SutureFunctionOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">垫片</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.Shim && AnswerData.Shim.split('|').includes('垫片形状')}">垫片形状</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.Shim && AnswerData.Shim.split('|').includes('垫片材质')}">垫片材质</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.Shim && AnswerData.Shim.split('|').includes('垫片大小')}">垫片大小</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.ShimOther">
        <span>其他：<span class="red">{{AnswerData.ShimOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.ShimOther" /> -->
      </div>
    </div>
    
    <div class="block">
      <p class="title">组合包装（根/包）</p>
      <div class="list">
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('1')}">1</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('2')}">2</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('3')}">3</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('4')}">4</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('6')}">6</span>
        <span class="item" :class="{'active': AnswerData && AnswerData.CombinationPackaging && AnswerData.CombinationPackaging.split('|').includes('8')}">8</span>
      </div>
      <div class="other" v-if="AnswerData && AnswerData.CombinationPackagingOther">
        <span>其他：<span class="red">{{AnswerData.CombinationPackagingOther}}</span></span>
        <!-- <input type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.CombinationPackagingOther" /> -->
      </div>
    </div>

    <div class="block">
      <p class="title">其他产品需求</p>
      <span class="font" v-if="AnswerData && AnswerData.Others">{{AnswerData.Others}}</span>
      <!-- <input class="input" type="text" readonly="readonly" v-if="AnswerData" v-model="AnswerData.SutureLength" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductFeedbackList',
  data() {
    return {
      AnswerData: null
    }
  },
  computed: {
    id() {
      return this.$route.query.id;
    }
  },
  created() {
    if(this.id) {
      this.getList();
    }
  },
  methods: {
    getList() {
      this.$axios.get("/Api/Api/Web/Answer/GetAnsModel?code="+this.id).then((res) => {
        this.AnswerData = res.Data;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .info {
    font-size: 14px;
    color: #333333;
    margin-bottom: 5px;
  }
  .info:last-of-type {
    margin-bottom: 10px;
  }
  .block {
    margin-bottom: 15px;
    .title {
      font-size: 15px;
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .item {
        font-size: 13px;
        display: inline-block;
        min-width: 30px;
        padding: 6px 8px;
        text-align: center;
        border: 1px solid #cccccc;
        background-color: #FFFFFF;
        color: #333333;
        border-radius: 6px;
        margin-bottom: 10px;
      }
      .item:not(:last-of-type) {
        margin-right: 9px;
      }
      .active {
        background-color: #C70C3C;
        border: 1px solid #C70C3C;
        color: #FFFFFF;
      }
    }
    .other {
      font-size: 13px;
      input {
        font-size: 13px;
        height: 26px;
        border: 1px solid #cccccc;
        margin-left: 8px;
        padding: 0 8px;
      }
    }
    .input {
      font-size: 13px;
      height: 26px;
      border: 1px solid #cccccc;
      padding: 0 8px;
      margin-top: 10px;
    }
    .font {
      display: inline-block;
      font-size: 13px;
      color: #CA001B;
      margin-top: 5px;
    }
    .red {
      color: #CA001B;
    }
  }
}
</style>